<template>
  <v-row>
    <v-container>
      <v-sheet
        v-if="loading"
        :color="`grey`"
        class="pa-3"
      >
        <v-skeleton-loader
          class="mx-auto"
          type="article"
        >
        </v-skeleton-loader>
        <v-skeleton-loader
          class="d-flex justify-center"
          type="button"
        >
        </v-skeleton-loader>
        <v-skeleton-loader
          class="mx-auto"
          type="article"
        >
        </v-skeleton-loader>
        <v-skeleton-loader
          class="d-flex justify-center"
          type="button"
        >
        </v-skeleton-loader>
      </v-sheet>
      <fieldset
        v-else
        class="mx-auto mr-md-1"
        max-width="344"
        outlined
      >
        <legend class='ml-4 pl-2 pr-2 body-1 font-weight-bold'>Déposit</legend>
        <p class="ml-2">
        <v-row>
          <v-col
            cols="12"
            md="10"
            offset-md="1"
            class="mt-3"
          >

              <span class="text-body-1"><strong>C.A : </strong> {{ withCurrency(bond.amountBondPayed > 0 || bond.depositAlreadyPaidPending > 0?Math.ceil((bond.amountBondPayed+bond.depositAlreadyPaidPending)/bond.coeficientBond):bond.amount) }} </span><br/><br>
              <span class="text-body-1"><strong>Coef : </strong> {{ bond.coeficientBond }} </span><br /><br>
              <span class="text-body-1"><strong>Net à payer : </strong> {{ withCurrency(bond.amountBondPayed > 0 || bond.depositAlreadyPaidPending > 0?bond.amountBondPayed+bond.depositAlreadyPaidPending : bond.coeficientAmount) }} </span><br />
              <!--            <span class="text-body-1"><strong>Discount =</strong>  {{ bond.bonusActivationParameters }}% </span><br />-->
              <!--              <span-->
              <!--                class="text-body-1"-->
              <!--              ><strong>Net à payer :</strong> {{ withCurrency(bond.amountBondPayed > 0?bond.amountBondPayed+bond.depositAlreadyPaidPending-Math.ceil(((bond.amountBondPayed+bond.depositAlreadyPaidPending)*bond.bonusActivationParameters)/100) : bond.netPayable ) }}  </span><br />-->
              <span
                v-if="bond.amount!==undefined && bond.depositAlreadyPaidPending === 0 && bond.amountBondPayed === 0"
                class="d-block text-truncate text--primary"
              ><br>
                <a
                  target="_blank"
                  class="text-decoration-none info--text"
                  @click.prevent="seeProforma"
                ><v-icon class="info--text">{{ icon.mdiFileDocumentOutline }}</v-icon> Pro format
                </a>
              </span>

        </v-col>
        <v-col
          cols="12"
          md="12"
          class="text-center"
        >
          <v-chip
            v-if="bond.depositAlreadyPaidPending !== 0"
            if="item.state===0"
            class="ma-2 text-center"
            color="#FFF6ECFF"
            >
              <b class="custom-warning--text">En cours de traitement </b>
            </v-chip>
            <v-chip
              v-else-if="bond.amountBondPayed !== 0"
              if="item.state===0"
              class="ma-2 text-center"
              color="#F1FFE8FF"
          >
            <b class="success--text">deposit payé <v-icon>fa-solid fa-check</v-icon></b>
          </v-chip>
        </v-col>
        <v-col
          cols="12"
          md="12"
          class="text-center mb-3"
        >
          <v-btn
              v-if="bond.depositAlreadyPaidPending === 0 && bond.amountBondPayed === 0"
              class="p-5"
              color="primary mb-1"
              @click="dialog=true"
              x-small
            >
              Payer le deposit
            </v-btn>
            <!--          <v-btn-->
            <!--            class="p-5"-->
            <!--            color="primary"-->
            <!--            @click="dialogPublic=true"-->
            <!--          >-->
            <!--            Payer la caution-->
            <!--          </v-btn>-->
            <v-btn
              v-else-if="bond.amountBondPayed !== undefined"
              color="primary"
              outlined
              @click="gotoListPayment"
              x-small
            >
              <v-icon left>
                {{ icon.mdiFileSearch }}
              </v-icon>
              Détail de paiement
            </v-btn>
          </v-col>
        </v-row>
        </p>
      </fieldset>

      <fieldset
        v-if="hasNewBond"
        class="mx-auto mr-md-1 mt-4"
        max-width="344"
        outlined
      >
        <legend class='ml-4 pl-2 pr-2 body-1 font-weight-bold'>Déposit Additionnel</legend>
        <p class='ml-2'>
          <v-row>
            <v-col
              cols="12"
              md="10"
              offset-md="1"
              class="mt-3"
            >
              <span class="text-body-1"><strong>C.A :</strong> {{ withCurrency(Math.ceil(additionAmount.netPayable/bond.coeficientBond)) }}</span><br/><br>
              <span class="text-body-1"><strong>Coef :</strong> {{ bond.coeficientBond }} </span><br/><br>
              <span class="text-body-1"><strong>Net à payer :</strong> {{ withCurrency(additionAmount.netPayable) }} </span><br />
              <!--          <span class="text-body-1"><strong>Discount = </strong>  {{ bond.bonusActivationParameters }}% </span><br />-->
              <!--            <span class="text-body-1"><strong>Net à payer :</strong> {{ withCurrency(additionAmount.netPayable ) }} </span><br />-->
              <br> <span
              class="d-block text-truncate text--primary"
              v-if="bond.depositAlreadyPaidPending === 0"
            >
              <a
                target="_blank"
                class="text-decoration-none info--text"
                @click.prevent="seeProforma"
              ><v-icon class="info--text">{{ icon.mdiFileDocumentOutline }}</v-icon> Pro format
              </a>
            </span>
            </v-col>
            <v-col
              cols="12"
              md="12"
              class="text-center mb-3"
            >
              <v-btn
                class="primary p-5"
                @click="dialog=true"
                v-if="bond.depositAlreadyPaidPending === 0"
                x-small
              >
                Payer le deposit
              </v-btn>
              <v-chip
                v-else
                if="item.state===0"
                class="text-center"
                color="#FFF6ECFF"
              >
                <b class="custom-warning--text">Il y a déjà un paiement en cours </b>
              </v-chip>
            </v-col>
          </v-row>
        </p>

      </fieldset>
      <v-dialog
        v-model="dialog"
        max-width="800px"
      >
        <pay-form
          :montant="amount"
          @finish="dialog=false"
        />
      </v-dialog>
    <!--    <v-dialog-->
    <!--      v-model="dialogPublic"-->
    <!--      max-width="800px"-->
    <!--    >-->
    <!--      <pay-form-public-->
    <!--        :montant="amount"-->
    <!--        @finish="dialogPublic=false"-->
    <!--      />-->
    <!--    </v-dialog>-->
    </v-container>
  </v-row>
</template>

<script>
import { mdiFileSearch, mdiFileDocumentOutline } from '@mdi/js'
import {
  computed, onBeforeMount, onMounted, ref,
} from '@vue/composition-api'
// eslint-disable-next-line import/extensions
import PayForm from '@/views/caution/PayForm'
// eslint-disable-next-line import/extensions
import router from '@/router'
import Http from '@/helpers/http'

export default {
  name: 'CautionCard',
  components: { PayForm },
  setup() {
    const dialog = ref(false)
    const dialogPublic = ref(false)
    const loading = ref(true)
    const payCaution = () => {
      console.log('emit')
    }

    const gotoListPayment = () => {
      router.push({ name: 'list-caution-all' })
    }

    const bond = ref({})
    const amount = ref(0)
    const additionAmount = ref({})

    const currency = ref('XAF')

    const withCurrency = price => {
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(price)) {
        return 0 + currency.value
      }

      return `${Number(price).toLocaleString()} ${currency.value}`
    }

    const seeProforma = () => {
      router.push({ name: 'caution-proforma' })
    }

    const getDeposit = () => {
      Http.get(`get-deposit-payment-information/${JSON.parse(localStorage.getItem('defaultContrat')).id}`)
        .then(response => {
          if (response.status === 200) {
            bond.value = response.data

            bond.value.amountBond = Math.ceil(bond.value.amountBond)
            bond.value.netPayable = Math.ceil(bond.value.netPayable)
            bond.value.coeficientAmount = Math.ceil((bond.value.amount * parseFloat(bond.value.coeficientBond)))

            console.log('caution information')
            console.log(bond.value)
            // amount.value = Math.ceil(bond.value.netPayable)
            amount.value = Math.ceil(bond.value.coeficientAmount)
            // eslint-disable-next-line no-use-before-define
            if (hasNewBond.value) {
              // const montantCoef = Math.ceil(Math.abs((bond.value.coeficientAmount - (bond.value.depositAlreadyPaidPending+bond.value.amountBondPayed))))
              // const montant = montantCoef / parseFloat(bond.value.coeficientBond)
              // const montantNet = Math.abs(montantCoef - ((montantCoef * bond.value.bonusActivationParameters) / 100))
              additionAmount.value = {
                amount: bond.value.amount,
                coeficientAmount: bond.value.coeficientAmount,
                netPayable: bond.value.netPayable,
              }
              amount.value = Math.ceil(additionAmount.value.coeficientAmount)
              // amount.value = Math.ceil(additionAmount.value.netPayable)
            }

            // if (bond.value.depositAlreadyPaidPending < bond.netPayable) {
            //   const montant = Math.abs(bond.value.amount - bond.value.amountPaidWithoutSubsidy)
            //   const montantCoef = montant * parseFloat(bond.value.coeficientBond)
            //   const montantNet = Math.abs(montantCoef - ((montantCoef * bond.value.bonusActivationParameters) / 100))
            //   additionAmount.value = {
            //     amount: Math.ceil(montant),
            //     amountBond: Math.ceil(montantCoef),
            //     netPayable: Math.ceil(montantNet),
            //     coeficientBond: bond.value.coeficientBond,
            //     bonusActivationParameters: bond.value.bonusActivationParameters,
            //   }
            //   amount.value = Math.ceil(additionAmount.value.netPayable)
            // }
          }
          loading.value = false
        })
        .catch(error => {
          console.log(error)
        })
    }

    const hasNewBond = computed(() => {
      // eslint-disable-next-line no-restricted-globals
      // if ((bond.value.depositAlreadyPaidPending !== bond.value.netPayable)) {
      return (bond.value.depositAlreadyPaidPending+bond.value.amountBondPayed)>0 && bond.value.netPayable !== (bond.value.depositAlreadyPaidPending+bond.value.amountBondPayed) && bond.value.netPayable > 0
    })

    onBeforeMount(() => {
      getDeposit()
    })

    return {
      bond,
      getDeposit,
      dialog,
      dialogPublic,
      payCaution,
      amount,
      gotoListPayment,
      additionAmount,
      currency,
      withCurrency,
      hasNewBond,
      seeProforma,
      loading,
      icon: {
        mdiFileSearch,
        mdiFileDocumentOutline,
      },
    }
  },
}
</script>

<style scoped>
.custom-warning--text{
  color: darkorange;
//background-color: #ffcfcf;
}
</style>
