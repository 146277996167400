<template>
  <div class="mt-4">
    <v-card>
      <snack-view
        :message="snackbar.message"
        :activate="snackbar.show"
        :bg-color="snackbar.color"
      ></snack-view>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="2"
            class="border"
          >
            <v-btn
              dense
              text
              outlined
              class="mr-1"
              @click="dialogLocal=true"
            >
              <v-icon left>
                {{ icons.mdiPlus }}
              </v-icon>
              Ajouter
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            offset-md="6"
            md="4"
          >
            <v-text-field
              v-model="search"
              :append-icon="icons.mdiMagnify"
              label="Rechercher"
              single-line
              hide-details
              dense
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- Table -->
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <v-card-text>
            <v-row>
              <v-col
                v-if="!productList.length && loading && isPrepaye"
                :color="`grey`"
                class="pa-3"
                cols="12"
              >
                <v-skeleton-loader
                  class="mx-auto"
                  type="table, data-table"
                >
                </v-skeleton-loader>
              </v-col>
              <v-col
                v-else-if="!productList.length && loading"
                :color="`grey`"
                class="pa-3"
                cols="12"
                md="8"
              >
                <v-skeleton-loader
                  class="mx-auto"
                  type="table, data-table"
                >
                </v-skeleton-loader>
              </v-col>
              <v-col
                v-else
                cols="12"
                :md="isPrepaye?'12':'8'"
              >
                <v-data-table
                  :headers="headers"
                  :items="productList"
                  :search="search"
                  :options="dataOptions"
                  :items-per-page="15"
                  sort-by="id"
                  class="table-kitchen-sink"
                >
                  <template #[`item.id`]="{item}">
                    <div class="d-flex align-center">
                      <div class="d-flex flex-column ms-3">
                        <span class="d-block font-weight-semibold text-truncate text--primary">{{ item.id }}</span>
                      </div>
                    </div>
                  </template>
                  <!-- product -->
                  <template #[`item.name`]="{item}">
                    <div class="d-flex align-center">
                      <div class="d-flex flex-column ms-3">
                        <span class="d-block font-weight-semibold text-truncate text--primary">{{ item.name }}</span>
                      </div>
                    </div>
                  </template>
                  <!-- product -->
                  <template #[`item.local_type_id`]="{item}">
                    <div class="d-flex align-center">
                      <div class="d-flex flex-column ms-3">
                        <span class="d-block font-weight-semibold text-truncate text--primary">{{ nameTypeLocal(item.local_type_id) }}</span>
                      </div>
                    </div>
                  </template><!-- product -->
                  <template #[`item.decoder`]="{item}">
                    <div class="d-flex align-center">
                      <div class="d-flex flex-column ms-3">
                        <span class="d-block font-weight-semibold text-truncate text--primary">{{ item.decoders.length }}</span>
                      </div>
                    </div>
                  </template>

                  <template #[`item.id`]="{item}">
                    <div class="d-flex align-center">
                      <div class="d-flex flex-column ms-3">
                        <span class="d-block font-weight-semibold text-truncate text--primary">{{ productList.length - productList.indexOf(item) }}</span>
                      </div>
                    </div>
                  </template>
                  <template #[`item.client`]="{item}">
                    <div class="d-flex align-center">
                      <div class="d-flex flex-column ms-3">
                        <span class="d-block font-weight-semibold text-truncate text--primary">{{ item.client }}</span>
                      </div>
                    </div>
                  </template>
                  <!-- action -->

                  <template #[`item.actions`]="{ item }">
                    <div class="col-12 text-center">
                      <v-btn
                        v-if="isVisu()"
                        outlined
                        x-small
                        color="info"
                        class="ml-1 mt-1"
                        @click="editItem(item)"
                      >
                        <v-icon
                          x-small
                          class="me-2"
                        >
                          {{ icons.mdiPencilOutline }}
                        </v-icon>
                        Modifier
                      </v-btn>

                      <v-btn
                        class="ml-1 mt-1"
                        outlined
                        x-small
                        color="warning"
                        @click="goTolistDecodeur(item,)"
                      >
                        <v-icon
                          small
                        >
                          {{ icons.mdiEyeOutline }}
                        </v-icon>
                        Décodeurs
                      </v-btn>

                      <v-btn
                        v-if="isVisu()"
                        class="ml-1"
                        outlined
                        x-small
                        color="error"
                        @click="deleteItem(item)"
                      >
                        <v-icon
                          small
                        >
                          {{ icons.mdiDeleteOutline }}
                        </v-icon>
                        Supprimer
                      </v-btn>
                    </div>
                  </template>
                </v-data-table>
              </v-col>
              <v-col
                v-if="!isPrepaye && productList.length"
                cols="12"
                md="4"
              >
                <v-card-text>
                  <v-row class="my-2">
                    <v-col
                      cols="12"
                      md="10"
                      offset-md="1"
                    >
                      <caution-card class="mt-n4"></caution-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card-text>
        </v-col>
      </v-row>

      <!-- dialog édition -->
      <v-dialog
        v-model="dialog"
        max-width="500px"
      >
        <v-card>
          <v-card-title>
            <span>éditer le {{ !isContratFlotte?'local':'collaborateur' }} <b>{{ editedItem.name }}</b></span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="editedItem.name"
                    outlined
                    dense
                    :label="`${!isContratFlotte?'Nom de l\'emplacement':'Nom du collaborateur'}`"
                  ></v-text-field>
                  <v-select
                    v-model="editedItem.local_type_id"
                    outlined
                    dense
                    :items="typeLocal"
                    item-text="name"
                    item-value="id"
                    :label="`${!isContratFlotte?'Type d\'emplacement':'Type de collaborateur'}`"
                    :disabled="isContratFlotte?true:false"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              outlined
              @click="close"
            >
              Annuler
            </v-btn>
            <v-btn
              color="primary"
              @click="save"
            >
              Enregistrer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- dialog newLocal -->
      <v-dialog
        v-model="dialogLocal"
        max-width="500px"
      >
        <v-card>
          <v-card-title>
            <span>Nouveau {{ !isContratFlotte?'Emplacement':'collaborateur' }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="editedItem.name"
                    outlined
                    dense
                    :label="`${!isContratFlotte?'Nom de l\'emplacement':'Nom du collaborateur'}`"
                  ></v-text-field>
                  <v-select
                    v-model="editedItem.local_type"
                    outlined
                    dense
                    :items="typeLocal"
                    item-text="name"
                    item-value="id"
                    :label="`${!isContratFlotte?'Type d\'emplacement':'Type de collaborateur'}`"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              outlined
              @click="closeLocal"
            >
              Annuler
            </v-btn>
            <v-btn
              v-if="isVisu()"
              color="primary"
              @click="addNewLocal"
            >
              Enregistrer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- dialog suppression -->
      <v-dialog
        v-model="dialogDelete"
        max-width="1000px"
      >
        <v-card>
          <v-card-title>
            Êtes-vous sûr de vouloir supprimer cet emplacement ?
          </v-card-title>
          <v-card-text>
            <h3>Cela entrainera la suppression de ces décodeurs</h3>
            <v-data-table
              :headers="headersDeco"
              :items="decodeurListFormatted"
              :divider="true"
              :items-per-page="15"
              :search="search"
              item-key="id"
            >
              <!--      <template v-slot:item = "{ item }">-->
              <!--        <decodeur-table :data="item" :key="item.number" :options="option" :formules="formule"></decodeur-table>-->
              <!--      </template>-->

              <template #[`item.is_auto`]="{item}">
                <div class="d-flex align-center">
                  <div class="d-flex flex-column ms-3">
                    <span :class="item.reabo_auto===true?'success--text':'primary--text'">
                      {{ item.reabo_auto === true ? 'Activé' : 'Désactivé' }}
                    </span>
                  </div>
                </div>
              </template>

              <template #[`item.formula`]="{item}">
                <div class="d-flex align-center">
                  <div class="d-flex flex-column justify-content-center text-center">
                    <span class="d-block font-weight-semibold text--primary text-truncate"> {{
                      item.formula.label !== undefined ? item.formula.label : "N/A"
                    }}</span>
                    <small> <span class="font-weight-semibold text--primary">Option :</span>
                      {{
                        item.option !==null ?item.option.nom:'N/a'
                      }}</small>
                  </div>
                </div>
              </template>

              <template #[`item.start_subscription`]="{item}">
                <div class="d-flex align-center">
                  <div class="d-flex flex-column">
                    <span class="d-block font-weight-semibold success--text text-truncate">{{
                      formatDate(item.start_subscription)
                    }}</span>
                  </div>
                </div>
              </template>

              <template #[`item.end_subscription`]="{item}">
                <div class="d-flex align-center">
                  <div class="d-flex flex-column ms-3">
                    <span class="d-block font-weight-semibold error--text text-truncate">{{
                      formatDate(item.end_subscription)
                    }}</span>
                  </div>
                </div>
              </template>

              <!-- status -->
              <template #[`item.state`]="{item}">
                <v-chip
                  small
                  :color="statusColor[status[item.state]]"
                  :class="`${statusColor[status[item.state]]}--text`"
                  class="v-chip-light-bg"
                >
                  <small v-if="item.state===1"> {{ status[item.state] }}</small>
                  <small v-else> {{ status[item.state] }} </small>
                </v-chip>
              </template>
              <template #[`item.actions`]="{ item }">
                <div class="col-12 text-center">
                  <v-btn
                    class="ml-1"
                    outlined
                    x-small
                    color="error"
                    @click="deleteItem(item)"
                  >
                    <v-icon
                      small
                    >
                      {{ icons.mdiDeleteOutline }}
                    </v-icon>
                    Supprimer
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              outlined
              @click="closeDelete"
            >
              Annuler
            </v-btn>
            <v-btn
              color="success"
              @click="deleteItemConfirm"
            >
              Confirmer
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import {
  onMounted, ref, computed, onBeforeMount,
} from '@vue/composition-api'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiEyeOutline,
  mdiPlus,
} from '@mdi/js'
import data from '@/views/locaux/datatable'
import Http from '@/helpers/http'
// eslint-disable-next-line import/extensions
import SnackView from '@/views/SnackView'
import { useRouter } from '@core/utils'
// eslint-disable-next-line import/extensions
import CautionCard from '@/views/caution/CautionCard'
// eslint-disable-next-line camelcase
import { is_category_flotte, PREPAYE_TYPE } from '@/helpers/constants/contrat'
import moment from 'moment'
import { isVisu } from '@/helpers/constants/roles'

export default {
  components: {
    CautionCard,
    SnackView,
  },

  setup() {
    const search = ref('')
    const productList = ref([])
    const { router } = useRouter()
    const dialogLocal = ref(false)

    const goTolistDecodeur = local => {
      // eslint-disable-next-line no-shadow,radix
      const localId = parseInt(local.id)

      router.push({ name: 'list-decodeur', params: { id: localId, data: local } })
    }
    const isPrepaye = computed(() => PREPAYE_TYPE === JSON.parse(localStorage.getItem('defaultContrat')).customer_type_id)
    const snackbar = ref({
      color: 'success',
      message: '',
      show: false,
    })
    const typeLocal = ref([])
    const typeLocalBackup = ref(null)

    const contrat = JSON.parse(localStorage.getItem('defaultContrat'))

    const nameTypeLocal = localId =>
      // eslint-disable-next-line array-callback-return,consistent-return,newline-before-return,implicit-arrow-linebreak
      typeLocalBackup.value.filter(item => (item.id === localId))[0].name

    const loadLocals = () => {
      Http.get(`get-locals/${JSON.parse(localStorage.getItem('defaultContrat')).id}`)
        .then(response => {
          productList.value = response.data
          console.log('locals')
          console.log(productList.value)
        })
        .catch(error => {
          console.error('Erreur :', error)
        })
    }
    const loading = ref(true)
    onMounted(() => {
      setTimeout(() => {
        loading.value = false
      }, 5000)
    })

    const isContratFlotte = ref(is_category_flotte())

    const decodeurListFormatted = computed(() => {
      decodeurList.value.forEach(item => {
        // eslint-disable-next-line no-param-reassign
        const valeur = remainingDay(item)
        // eslint-disable-next-line no-param-reassign
        item.remaining = valeur.remaining
        // eslint-disable-next-line no-param-reassign
        item.days = valeur.day
        // eslint-disable-next-line no-param-reassign
        item.echu = valeur.echu
        // eslint-disable-next-line no-param-reassign
        item.reabo_auto = item.is_auto === 1
        // eslint-disable-next-line no-param-reassign
        item.state = item.echu === false ? 1 : 2
      })

      return decodeurList.value
    })

    const updatePrel = ref(true)

    const formule = ref([])
    const option = ref([{
      alpha2: 'CM',
      code: 'AUCUNE',
      id: 0,
      nom: 'Aucune',
      pu: 0,
    }])

    const loadFormules = () => {
      Http.get('get-formules')
        .then(response => {
          formule.value = response.data
          formule.value.unshift({ label: 'Toute', code: '' })
        })
        .catch(error => {
          console.error('Erreur :', error)
        })
    }

    const loadOptions = () => {
      Http.get('get-options')
        .then(response => {
          option.value = [...option.value, ...response.data]
        })
        .catch(error => {
          console.error('Erreur :', error)
        })
    }

    onBeforeMount(() => {
      loadFormules()
      loadOptions()
    })

    const nomOption = code => {
      // eslint-disable-next-line array-callback-return,no-return-assign
      let name = option.value.filter(item => name = item.code === code)
      if (name.length === 1) return name[0].nom

      return code
    }

    const formatDate = dateString => {
      if (dateString !== null) {
        return moment(dateString, 'YYYY-MM-DD').format('DD/MM/YYYY')
      }

      return 'N/A'
    }

    const decodeurList = ref([])

    const currentDate = moment().format('YYYY-MM-DD')
    const remainingDay = decodeur => {
      const startSubscription = (decodeur.start_subscription === null) ? moment(decodeur.created_at).format('YYYY-MM-DD') : decodeur.start_subscription
      const start = moment(startSubscription, 'YYYY-MM-DD')
      const end = moment(decodeur.end_subscription, 'YYYY-MM-DD')
      const days = moment(start).diff(end, 'days')
      const remainingDays = moment(end).diff(currentDate, 'days')
      if (remainingDays <= 0) {
        const mday = Math.abs(remainingDays)

        // const pourcentage = Math.round(Math.abs(((remainingDays * 100) / days)))

        return { remaining: 100, day: mday, echu: true }
      }
      const pourcentage = Math.round(Math.abs(((remainingDays * 100) / days)))
      const rday = Math.abs(remainingDays)

      return { remaining: pourcentage, day: rday, echu: false }
    }

    const statusColor = {
      /* eslint-disable key-spacing */
      Actif: 'success',
      Echu: 'error',
      /* eslint-enable key-spacing */
    }

    return {
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiEyeOutline,
        mdiPlus,
      },
      search,
      dialogLocal,
      goTolistDecodeur,
      isVisu,
      headers: [
        { text: 'N°', value: 'id', divider: true },
        { text: !isContratFlotte.value ? 'Nom de l\'emplacement' : 'Nom du collaborateur', value: 'name', divider: true },
        { text: !isContratFlotte.value ? 'Type d\'emplacement' : 'Type de collaborateur', value: 'local_type_id', divider: true },
        { text: 'Nbre de décodeurs', value: 'decoder', divider: true },
        {
          text: 'Actions', value: 'actions', sortable: false, divider: true, align: 'center',
        },
      ],
      headersDeco: [
        { text: 'Emplacement', value: 'location', align: 'center' },
        { text: 'N° Décodeur', value: 'number', align: 'center' },
        { text: 'Offre', value: 'formula', align: 'center' },
        { text: 'Prél auto', value: 'is_auto', align: 'center' },
        { text: 'Début', value: 'start_subscription', align: 'center' },
        { text: 'Fin', value: 'end_subscription', align: 'center' },

        // { text: 'Jours restants', value: 'remaining', align: 'center' },
        { text: 'Etat', value: 'state', align: 'center' },

        // {
        //   text: 'Action', value: 'actions', sortable: false, divider: true,
        // },
      ],
      dataOptions: {
        sortBy: ['id'],
        sortDesc: [true],
      },
      snackbar,
      productList,
      loadLocals,
      nameTypeLocal,
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
      dialog: false,
      dialogDelete: false,
      typeLocal,
      loading,
      isPrepaye,
      typeLocalBackup,
      contrat,
      isContratFlotte,
      decodeurListFormatted,
      updatePrel,
      nomOption,
      formatDate,
      formule,
      option,
      decodeurList,
      remainingDay,
      statusColor,
      status: {
        1: 'Actif',
        2: 'Echu',
      },

    }
  },
  watch: {
    dialog(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.close()
    },
    dialogDelete(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.closeDelete()
    },
  },
  mounted() {
    this.getLocalType()
  },
  created() {
    // this.$http.get('/data-table/data').then(res => {
    //   this.productList = res.data
    // })

    this.loadLocals()
    console.log('loadformule')

    // console.error('Oops, Unable to login!')
    // if (error.status === 422) {
    //   console.log('error 422 :>> ', error.data.errors)
    //   errors.value = error.data.errors
    // } else if (error.status === 401) {
    //   console.log('error 401 :>> ', error.data)
    //   errors.value = { dataError: error.data }
    // } else {
    //   console.log('error :>> ', error)
    //   errors.value = { dataError: 'Votre connexion internet est instable' }
    // }
    // })
  },
  methods: {
    initialize() {
      this.userList = JSON.parse(JSON.stringify(data))
    },

    getLocalType() {
      Http.get('local-type', { errorHandle: false })
        .then(async response => {
          // eslint-disable-next-line no-multi-assign
          this.typeLocal = this.typeLocalBackup = await response.data
        })
    },

    editItem(item) {
      console.log(item)
      this.editedIndex = this.productList.indexOf(item)
      this.editedItem = { ...item }
      this.getLocalType()
      this.dialog = true
    },

    addNewLocal() {
      console.log(this.editedItem)
      const params = {
        contractId: JSON.parse(localStorage.getItem('defaultContrat')).id,
        locals: [{
          LocalName: this.editedItem.name,
          LocalType: this.editedItem.local_type,
          Decodeurs: [],
        }],
      }

      Http.post(
        'local',
        params,
      ).then(rep => {
        if (rep.status === 201) {
          this.loadLocals()
          this.closeLocal()
        }
      })
        .catch(error => {
          console.log(error)
        })

      // const mItem = this.editedItem
      // this.snackbar.show = false
      // console.log(mItem)
      // if (this.editedIndex > -1) {
      //   Object.assign(this.productList[this.editedIndex], mItem)
      // } else {
      //   this.productList.push(mItem)
      // }
    },

    deleteItem(item) {
      this.editedIndex = this.productList.indexOf(item)
      this.editedItem = { ...item }
      this.decodeurList = []
      this.decodeurList = item.decoders
      console.log(this.decodeurList)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.productList.splice(this.editedIndex, 1)
      const mItem = this.editedItem
      this.snackbar.show = false
      console.log(mItem)

      Http.get(`/delete-local/${this.editedItem.customer_segment_id}/${this.editedItem.id}`)
        .then(response => {
          this.snackbar.message = response.message
          this.snackbar.show = true
          this.closeDelete()
        })
        .catch(error => {
          console.log(error)
        })
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    closeLocal() {
      this.dialogLocal = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    save() {
      const mItem = this.editedItem
      this.snackbar.show = false
      console.log(mItem)
      if (this.editedIndex > -1) {
        Object.assign(this.productList[this.editedIndex], mItem)
      } else {
        this.productList.push(mItem)
      }

      Http.put(`local/${this.editedItem.id}`, { name: this.editedItem.name, localTypeId: this.editedItem.local_type_id })
        .then(response => {
          this.snackbar.message = response.message
          this.snackbar.show = true
        })
        .catch(error => {
          console.log(error)
        })

      this.close()
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
